import React, { Component, Fragment } from "react";
import { themeGet } from "styled-system";
import styled from "styled-components";
import { Link } from "@reach/router";
import { resetPassword } from "../../services/moocfi";
import * as routes from "../../constants/routes";
import { FormattedMessage, injectIntl } from "react-intl";
import StyledForm from "../StyledForm";
import { SmallContainer } from "../Grid";
import TextF2 from "../TextF2";
import TextF7 from "../TextF7";
import TextF8 from "../TextF8";

import emailExample from "./email-example.png";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

const StyledF2 = styled(TextF2)`
  text-align: center;
  color: white;
`;

const SignUpLink = styled.p`
  text-align: center;
  margin-top: 30px;
`;

const Error = styled.p`
  color: #ff7351;
`;

const CompletedMessage = styled.p`
  text-align: center;
  padding: 40px;
  margin: auto 5%;
  margin-bottom: 40px;
  background-color: rgba(50, 194, 162, 0.4);
`;

const SubmitButton = styled.button`
  font-size: 1rem;
  outline: none !important;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  line-height: "1";
  color: ${props =>
    props.disabled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,1)"};
  max-width: 280px;
  padding: 1rem;
  line-height: 2rem;
  border-radius: 2rem;
  background: #32c2a2;
  border: none;
  width: ${props => (props.disabled ? "80%" : "100%")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  transition: all 0.2s ease;
  margin: 0 auto;
  margin-top: 35px;
  display: block;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
  :hover {
    cursor: pointer;
  }
`;

const StyledF7 = styled(TextF7)`
  padding-top: 20px;
  text-align: center;
  color: white;
`;

const StyledF8 = styled(TextF8)`
  text-align: center;
  color: white;
`;

const EmailExample = styled.img`
  width: 100%;
  height: auto;
  padding: 20px 40px;
`;

const INITIAL_STATE = {
  email: "",
  error: undefined,
  completeMessage: undefined
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    event.preventDefault();
    const { email } = this.state;

    resetPassword(email)
      .then(res => {
        if (!res.success) {
          this.setState({
            error: res.errors
          });
        } else {
          this.setState({
            ...INITIAL_STATE,
            ...{
              completeMessage: this.props.intl.formatMessage({
                id: "pwForget.resetEmailSent"
              })
            }
          });
        }
      })
      .catch(error => {
        this.setState(updateByPropertyName("error", JSON.stringify(error)));
      });
  };

  render() {
    const { email, error, completeMessage } = this.state;
    const isInvalid = email === "";
    const { intl } = this.props;

    return (
      <StyledForm error={error}>
        <SmallContainer pt={6}>
          <StyledF2 color="#fff">
            <FormattedMessage id="pwForget.title" />
          </StyledF2>
          {completeMessage ? (
            <Fragment>
              <CompletedMessage>
                <FormattedMessage id="pwForget.resetEmailSent" />
              </CompletedMessage>
              <StyledF8>
                <FormattedMessage id="pwForget.completedDescription" />
              </StyledF8>
              <EmailExample src={emailExample} />
              <StyledF8>
                <FormattedMessage id="pwForget.problemDescription" />
              </StyledF8>
            </Fragment>
          ) : (
            <Fragment>
              <StyledF7>
                <FormattedMessage id="pwForget.description" />
              </StyledF7>
              <form onSubmit={this.onSubmit}>
                <label htmlFor="email">
                  <FormattedMessage id="globals.yourEmail" />
                </label>
                <input
                  value={this.state.email}
                  onChange={event =>
                    this.setState(
                      updateByPropertyName("email", event.target.value)
                    )
                  }
                  type="email"
                  placeholder={this.props.intl.formatMessage({
                    id: "pwForget.emailPlaceholder"
                  })}
                  autoComplete="email"
                />

                {error && <Error>{error}</Error>}
                <SubmitButton disabled={isInvalid} type="submit">
                  <FormattedMessage id="pwForget.sendResetLink" />
                </SubmitButton>
                <SignUpLink>
                  <FormattedMessage id="signIn.noAccount" />
                  <Link to={intl.formatMessage({ id: "routes.SIGN_UP" })}>
                    {" "}
                    <FormattedMessage id="signIn.signUpNow" />
                  </Link>
                </SignUpLink>
              </form>
            </Fragment>
          )}
        </SmallContainer>
      </StyledForm>
    );
  }
}

export const PasswordForgetLink = () => (
  <p>
    <Link to={routes.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default injectIntl(PasswordForgetForm);
