import Text from "./Text";

const TextF8 = Text.withComponent("p");

TextF8.defaultProps = {
  fontSize: ["0.875rem", "1rem"],
  fontFamily: "TTNormsProRegular",
  lineHeight: "1.5",
  color: "#29264C"
};

export default TextF8;
