import Text from "./Text";

const TextF7 = Text.withComponent("p");

TextF7.defaultProps = {
  fontSize: ["1rem", "1.125rem"],
  fontFamily: "alkesRegular",
  lineHeight: "1.89",
  color: "#29264C"
};

export default TextF7;
